/* Sytles for container */
.navbar {
    background-color: black;
    display: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
    font-size: 18px;
}



/* Styles for right content */
 /* make it stick to web-name */
.right-content {
    display: flex;
    align-items: center;
    margin-right: 10px;
    gap:5px;
}


/*styles for the button */
.sign-out-btn {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

/* Styles for button hover */
.sign-out-btn:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

/* styles for log-in-text */
.log-in-text {
    color: white;
    font-size: 14px;
    /* best font for username */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    align-self: center;
    margin-left: 5px;
}

/* Styles for profile-pic */
.profile-pic {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.user-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 0;
}

@media (min-width: 768px) {
    .short-text {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .user-info {
        margin-bottom: -10px;
    }
}
  
